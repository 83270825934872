/* event banner css */
.Evnt .background{
    position: relative;
}
.Evnt .background img{
    width: 100%;
    object-fit: cover;
}
.Evnt .background .background-txt{
    position: absolute;
    top: 0;
    color: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 25px;
    text-align: center;
}
.Evnt .background .background-txt h4{
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 200% */
    text-transform: capitalize;
    padding-bottom: 24px;
}
.Evnt .background .background-txt h4 .color-change-word{
    color: #CDBBD7;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    text-transform: capitalize;
}
.Evnt .background .background-txt .event-hdr{
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; /* 125% */
    text-transform: capitalize;
    padding-bottom: 40px;
}
.Evnt .background .background-txt p{
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    margin-bottom: 0;
    padding-bottom: 0;
}

@media (min-width:768px){
    /* event banner css */
    .Evnt .background{
        position: relative;
    }
    .Evnt .background img{
        width: 100%;
        object-fit: cover;
    }
    .Evnt .background .background-txt{
        position: absolute;
        top: 0;
        color: #fff;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 75px;
        text-align: center;
    }
    .Evnt .background .background-txt h4{
        color: #FFF;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px; /* 200% */
        text-transform: capitalize;
        padding-bottom: 24px;
    }
    .Evnt .background .background-txt h4 .color-change-word{
        color: #CDBBD7;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        text-transform: capitalize;
    }
    .Evnt .background .background-txt .event-hdr{
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 60px; /* 125% */
        text-transform: capitalize;
        padding-bottom: 40px;
    }
    .Evnt .background .background-txt p{
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 166.667% */
        margin-bottom: 0;
        padding-bottom: 0;
    }

}
@media (min-width:1200px){
    /* event banner css */
    .Evnt .background{
        position: relative;
    }
    .Evnt .background img{
        width: 100%;
        object-fit: cover;
    }
    .Evnt .background .background-txt{
        position: absolute;
        top: 0;
        color: #fff;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 75px 370px;
        text-align: center;
    }
    .Evnt .background .background-txt h4{
        color: #FFF;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px; /* 200% */
        text-transform: capitalize;
        padding-bottom: 24px;
    }
    .Evnt .background .background-txt h4 .color-change-word{
        color: #CDBBD7;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        text-transform: capitalize;
    }
    .Evnt .background .background-txt .event-hdr{
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 80px;
        font-style: normal;
        font-weight: 700;
        line-height: 44px; /* 55% */
        text-transform: capitalize;
        padding-bottom: 40px;
    }
    .Evnt .background .background-txt p{
        color: #FFF;
        text-align: center;
        font-family: 'Inter';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px; /* 166.667% */
        margin-bottom: 0;
        padding-bottom: 0;
        padding-left: 20px;
        padding-right: 20px;
    }
}