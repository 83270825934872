/* LoginPage.css */

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
}

.login-card {
    border-radius: 12px;
    background: #FFF;
    padding: 80px 16px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    z-index: 1;
    width: 80%;
}

.login-card > .logo {
    width: 92px;
    height: 92px;
    margin-bottom: 40px;
}

.login-title {
    color: #050505;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 100% */
    padding: 0;
    margin: 0;
    padding-bottom: 24px;
}

.para-com{
    color: #A1A1A1;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
    width: auto;
    margin: 0;
    padding: 0;
    padding-bottom: 40px;
    margin: 0 auto;
}

.form-group {
    margin-bottom: 15px;
}

.label {
    color: #4E4E4E;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
    text-transform: capitalize;
    text-align: left;
    display: block;
}

.input {
    width: 100%;
    padding: 8px;
    /* border: 1px solid #ddd; */
    /* border-radius: 4px; */
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #D7D7D7;
    padding-bottom: 12px;
    background: #FFF;
}

.login-button {
    width: 100%;
    padding: 10px;
    background: #E53F71;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
}

.login-button:hover {
    background-color: #0056b3;
}

.password-input {
    position: relative;
}

.input {
    padding-right: 40px; /* Space for the eye icon */
}

.login-password {
    padding: 8px 40px 12px 8px !important;
    border: 1px solid #D7D7D7 !important;
    border-radius: 4px !important;
}

.eye-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    user-select: none;
}

.eye-icon.visible {
    color: #007bff;
}

.remember-label{
    color: #4E4E4E;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
    display: block;
    text-align: left;
}

.remember-label {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.remember-label input {
    margin-right: 4px;
    margin-top: 2px;
}


.background-img img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: fill;
    z-index: 0;
}

@media (min-width:1200px){
    /* LoginPage.css */

    .login-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        position: relative;
    }

    .login-card {
        border-radius: 12px;
        background: #FFF;
        padding: 80px 56px;
        border-radius: 8px;
        text-align: center;
        position: relative;
        z-index: 1;
        width: 35vw;
    }

    .login-card > .logo {
        width: 92px;
        height: 92px;
        margin-bottom: 40px;
    }

    .login-title {
        color: #050505;
        font-family: Montserrat;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 100% */
        padding: 0;
        margin: 0;
        padding-bottom: 24px;
    }

    .para-com{
        color: #A1A1A1;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 155.556% */
        width: 354px;
        margin: 0;
        padding: 0;
        padding-bottom: 40px;
        margin: 0 auto;
    }

    .form-group {
        margin-bottom: 15px;
    }

    .label {
        color: #4E4E4E;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px; /* 100% */
        text-transform: capitalize;
        text-align: left;
        display: block;
    }

    .input {
        width: 100%;
        padding: 8px;
        /* border: 1px solid #ddd; */
        /* border-radius: 4px; */
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid #D7D7D7;
        padding-bottom: 12px;
        background: #FFF;
    }

    .login-button {
        width: 100%;
        padding: 10px;
        background: #E53F71;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        text-transform: capitalize;
    }

    .login-button:hover {
        background-color: #0056b3;
    }

    .password-input {
        position: relative;
    }

    .input {
        padding-right: 40px; /* Space for the eye icon */
    }

    .eye-icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        cursor: pointer;
        user-select: none;
    }

    .eye-icon.visible {
        color: #007bff;
    }

    .remember-label{
        color: #4E4E4E;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        text-transform: capitalize;
        display: block;
        text-align: left;
    }

    .remember-label {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .remember-label input {
        margin-right: 4px;
        margin-top: 2px;
    }


    .background-img img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        object-fit: fill;
        z-index: 0;
    }
}

