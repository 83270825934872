.contctWrapper{
    padding: 40px 24px;
}
.small-card-flx{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}
.single-card{
    width: 100%;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 6px;
    background: #FCF7FF;
}

.single-card .img{
    padding: 10px;
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 6px;
    background: #F4E6FC;

}
.single-card .img svg{
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;   
}

.txt .main{
    color: #A83FE5;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 87.5% */
    text-transform: uppercase;   
    display: block;
    padding-bottom: 6px; 
}

.txt .small{
    color: #767676;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 116.667% */
}

.txt .small a{
    color: #767676;
}

.bottom-main-flx{
    margin-top: 28px;
    display: flex;
    flex-direction: column;
}
.bottom-main-flx .lft {
    display: block;
    padding: 40px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    margin-bottom: 48px;
    border-radius: 12px;
    background: #FAFAFA;
}
.bottom-main-flx .lft .lft-topic{ 
    color: #000;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px; /* 141.667% */
    text-transform: uppercase;
    padding-bottom: 40px;
    display: block;
}

.dropdwn .faq-item{
    display: flex;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;   
    border-radius: 6px;
    border-left: 5px solid #E9E9E9;
    background: #FFF;
    margin-bottom: 12px;

}

.dropdwn .faq-item .faq-question{
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: #383838;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    margin-bottom: 24px;
    cursor: pointer;
} 
.dropdwn .faq-item .faq-answer{
    color: #A1A1A1;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    justify-content: flex-start;
    text-align: left;
    line-height: 20px;
    display: flex;
    width: 100%;
}
/* ----------- */
.bottom-main-flx .rgt{
    display: block;
    padding: 40px 24px;
    gap: 40px;
    border-radius: 12px;
    background: #FAFAFA;
}
.bottom-main-flx .rgt .small-txt{
    color: #A83FE5;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
    padding-bottom: 16px;
    display: block;
}

.bottom-main-flx .rgt .big-txt{
    color: #000;

    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px; /* 141.667% */
    text-transform: uppercase;
    display: block;
    padding-bottom: 40px;
}


.fDiv{
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-bottom: 24px;
}
.fDiv:last-child{
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    margin-bottom: 0;
}
.fDiv label{
    color: #4E4E4E;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
    text-transform: capitalize;   
}

.fDiv input{
    display: flex;
    padding: 15px 24px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #E7E7E7;
    background: #FFF;   
}
.fDiv textarea{
    display: flex;
    height: 120px;
    padding: 15px 24px;
    align-items: flex-start;
    gap: 309px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #E7E7E7;
    background: #FFF;
}
.btnFrmSub{
    margin-top: 40px;
    display: flex;
    width: 200px;
    padding: 10px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: #A83FE5;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
    border: none;
}
@media(min-width:1200px){
    .contctWrapper{
        padding: 5.208333333333333vw 10.15625vw;
    }
    .small-card-flx{
        display: flex;
        flex-direction: row;
        column-gap: 3.125vw;
    }
    .single-card{
        width: 100%;
        padding: 2.0833333333333335vw;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.3020833333333333vw;
        border-radius: 6px;
        background: #FCF7FF;
    }
    
    .single-card .img{
        padding: 0.6510416666666666vw;
        display: flex;
        padding: 0.6510416666666666vw;
        align-items: flex-start;
        gap: 0.6510416666666666vw;
        border-radius: 6px;
        background: #F4E6FC;
    
    }
    .single-card .img svg{
        display: flex;
        width: 1.5625vw;
        height: 1.5625vw;
        justify-content: center;
        align-items: center;   
    }
    
    .txt .main{
        color: #A83FE5;
        font-family: Inter;
        font-size: 1.0416666666666667vw;
        font-style: normal;
        font-weight: 700;
        line-height: 0.9114583333333334vw; /* 87.5% */
        text-transform: uppercase;   
        display: block;
        padding-bottom: 6px;
    }
    
    .txt .small{
        color: #767676;
        font-family: Inter;
        font-size: 0.78125vw;
        font-style: normal;
        font-weight: 400;
        line-height: 0.9114583333333334vw; /* 116.667% */
    }

    .bottom-main-flx{
        margin-top: 1.8229166666666667vw;
        display: flex;
        flex-direction: row;
        column-gap: 3.125vw;
    }
    .bottom-main-flx .lft {
        display: block;
        padding: 2.6041666666666665vw 2.0833333333333335vw;
        flex-direction: column;
        align-items: flex-start;
        gap: 2.6041666666666665vw;
        width: 50%;
    }
    .bottom-main-flx .lft .lft-topic{ 
        color: #000;
        font-family: Montserrat;
        font-size: 1.5625vw;
        font-style: normal;
        font-weight: 700;
        line-height: 2.2135416666666665vw; /* 141.667% */
        font-variant: small-caps;
        padding-bottom: 2.6041666666666665vw;
        display: block;
    }
    
    .dropdwn .faq-item{
        display: flex;
        padding: 16px 1.5625vw;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.78125vw;   
        border-radius: 6px;
        border-left: 5px solid #E9E9E9;
        background: #FFF;
        margin-bottom: 0.78125vw;
    
    }
    
    .dropdwn .faq-item .faq-question{
        display: flex;
        width: 100%;
        justify-content: space-between;
        color: #383838;
        font-family: Inter;
        font-size: 0.9114583333333334vw;
        font-style: normal;
        font-weight: 500;
        line-height: 1.3020833333333333vw; /* 142.857% */
        margin-bottom: 1.953125vw;
        column-gap: 12px;
    } 
    .dropdwn .faq-item .faq-answer{
        color: #A1A1A1;
        font-family: Inter;
        font-size: 0.78125vw;
        font-style: normal;
        font-weight: 400;
        justify-content: flex-start;
        text-align: left;
        line-height: 1.3020833333333333vw;
        display: flex;
        width: 100%;
    }
    /* ----------- */
    .bottom-main-flx .rgt{
        display: block;
        padding: 2.6041666666666665vw 2.0833333333333335vw;
        gap: 2.6041666666666665vw;
        border-radius: 0.78125vw;
        background: #FAFAFA;
        width: 50%;
    }
    .bottom-main-flx .rgt .small-txt{
        color: #A83FE5;

        font-family: Inter;
        font-size: 0.9114583333333334vw;
        font-style: normal;
        font-weight: 600;
        line-height: 1.3020833333333333vw; /* 142.857% */
        text-transform: capitalize;
        padding-bottom: 16;
        display: block;
    }

    .bottom-main-flx .rgt .big-txt{
        color: #000;

        font-family: Montserrat;
        font-size: 1.5625vw;
        font-style: normal;
        font-weight: 700;
        line-height: 2.2135416666666665vw; /* 141.667% */
        font-variant: small-caps;
        display: block;
        padding-bottom: 2.6041666666666665vw;
    }


    .fDiv{
        display: flex;
        flex-direction: column;
        row-gap: 0.78125vw;
        margin-bottom: 1.5625vw;
    }
    .fDiv:last-child{
        display: flex;
        flex-direction: column;
        row-gap: 0.78125vw;
        margin-bottom: 0;
    }
    .fDiv label{
        color: #4E4E4E;

        font-family: Inter;
        font-size: 0.9114583333333334vw;
        font-style: normal;
        font-weight: 500;
        line-height: 0.9114583333333334vw; /* 100% */
        text-transform: capitalize;   
    }

    .fDiv input{
        display: flex;
        padding: 0.9765625vw 1.5625vw;
        align-items: flex-start;
        gap: 0.6510416666666666vw;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #E7E7E7;
        background: #FFF;   
    }
    .fDiv textarea{
        display: flex;
        height: 7.8125vw;
        padding: 0.9765625vw 1.5625vw;
        align-items: flex-start;
        gap: 20.1171875vw;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #E7E7E7;
        background: #FFF;
    }
    .btnFrmSub{
        margin-top: 2.6041666666666665vw;
        display: flex;
        width: 13.020833333333334vw;
        padding: 0.6510416666666666vw 0.9114583333333334vw;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        background: #A83FE5;
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 0.9114583333333334vw;
        font-style: normal;
        font-weight: 700;
        line-height: 1.3020833333333333vw; /* 142.857% */
        text-transform: capitalize;
        border: none;
    }
}