/* homepage css */
.hm-number-count{
    display: block;
    border-radius: 6px;
    width: 100%;
    background: #FAFAFA;
    box-shadow: 0px 4px 74px 0px rgba(0, 0, 0, 0.08);
}
.hm-number-count .hm-main-wrap-count .hm-flex-con{
    display: flex;
    padding: 32px 45px;
    justify-content: center;
    align-items: flex-start;
    gap: 60px;
    flex-direction: column;
    flex-wrap: nowrap;
}
.hm-number-count .hm-main-wrap-count .hm-flex-con .hm-single-bx{
    display: block;
    position: relative;
    width: fit-content;
}
.hm-number-count .hm-main-wrap-count .hm-flex-con .hm-single-bx::after{
    content: none;
    width: 0;
    height: 50%;
    position: absolute;
    border: 1px solid #E0E0E0;
    top: 50%;
    transform: translateY(-50%);
    right: -65%;
}
.hm-number-count .hm-main-wrap-count .hm-flex-con .hm-single-bx:last-child:after{
    content: none;
}

.hm-number-count .hm-main-wrap-count .hm-flex-con .hm-single-bx .hm-num-n{
    display: flex;
    flex-direction: column;
}
.hm-number-count .hm-main-wrap-count .hm-flex-con .hm-single-bx .hm-num-n .hm-nmbr{
    color: #2A2A2A;
    text-align: left;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px; /* 56.667% */
    text-transform: capitalize;
    padding-bottom: 20px; 
}
.hm-number-count .hm-main-wrap-count .hm-flex-con .hm-single-bx .hm-num-n  .hm-tagline{
    color: #767676;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 133.333% */
}

/* -------------------end of number section styles------------------------- */
/* start image-text flex section */

.hm-imgtxt-flex .hm-itf-main{
    display: none;
    padding: 30px 25px;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    text-align: center;
}
.hm-imgtxt-flex .hm-itf-main .hm-itf-left, .hm-itf-right{
    width: 100%;
}
.hm-imgtxt-flex .hm-itf-main .hm-itf-left, .hm-itf-right img{
    width: 100%;
    width: -webkit-fill-available;
    height: 100%;
    object-fit: cover;
}
.hm-imgtxt-flex .hm-itf-main .hm-itf-left .hm-itf-tgln{
    color: #727272;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 166.667% */
    text-transform: uppercase;
    padding-bottom: 14px;
}
.hm-imgtxt-flex .hm-itf-main .hm-itf-left .hm-itf-boldtxt{
    color: #444;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    text-transform: uppercase;
    width: 100%;
    padding: 0;
    margin: 0;
    padding-bottom: 32px;

}
.hm-imgtxt-flex .hm-itf-main .hm-itf-left .-hm-itf-para .hm-itf-common{
    color: #767676;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}
.hm-imgtxt-flex .hm-itf-main .hm-itf-left .-hm-itf-para .hm-itf-ul{
    list-style: none;
    padding: 0;
    color: #585858;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}
.hm-imgtxt-flex .hm-itf-main .hm-itf-left .hm-itf-btn{
    margin-top: 26px;
    display: flex;
    padding: 10px 24px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: #E53F71;
    border: none;
    margin: 0 auto;
    margin-bottom: 30px;
}
.hm-imgtxt-flex .hm-itf-main .hm-itf-left .hm-itf-btn:hover{
    background:#2a6edd;
}

.gallery-section .main-div-gallery .gallery-header{
    padding-top: 60px;
    padding-bottom: 40px;
}
.gallery-section .main-div-gallery .gallery-header .big-header{
    color: #000;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px; /* 106.25% */
    font-variant: small-caps;
    display: block;
    text-align: center;
}
.gallery-section .main-div-gallery .gallery-header .small-tg-gal{
    color: #767676;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
    padding-top: 16px;
    display: block;
}
.gallery-section .main-div-gallery .gallery-body{
    padding-left: 25px;
    padding-right: 25px;
    display: flex;
    flex-direction: column;
}

.gallery-section .main-div-gallery .gallery-body img{
    width: 100%;
    margin-bottom: 24px;
}

.gallery-section .main-div-gallery .button-for-gal button{
    display: flex;
    padding: 10px 24px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: #E53F71;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
    border: none;
    align-content: center;
    justify-content: center;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 60px;
    margin-top: 20px;
}


.EvntBottom .background{
    position: relative;
    background: linear-gradient(90deg, rgb(29 29 29) 0%, rgb(0 0 0) 100%);
    filter: blur(0px);
}
.EvntBottom .background img{
    width: 100%;
    object-fit: cover;
    opacity: 0.7;
}
.EvntBottom .background .background-txt{
    position: absolute;
    top: 0;
    color: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 25px;
    text-align: center;
}

.EvntBottom .background .background-txt .event-hdr{
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px; /* 106.25% */
    text-transform: capitalize;
    padding-bottom: 10px;
}
.EvntBottom .background .background-txt p{
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin-bottom: 32px;
}
.EvntBottom .background .background-txt button{
    display: flex;
    padding: 10px 24px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: #E53F71;
    box-shadow: 0px 2px 70px 0px rgba(229, 63, 113, 0.60);
    border: none;
    width: fit-content;
    margin: 0 auto;
}

@media (min-width:768px){
    .hm-number-count{
        display: block;
        border-radius: 6px;
        width: 100%;
        background: #FAFAFA;
        box-shadow: 0px 4px 74px 0px rgba(0, 0, 0, 0.08);
    }
    .hm-number-count .hm-main-wrap-count .hm-flex-con{
        display: flex;
        padding: 32px 45px;
        justify-content: center;
        align-items: flex-start;
        gap: 60px;
        flex-direction: row;
        flex-wrap: nowrap;
    }
    .hm-number-count .hm-main-wrap-count .hm-flex-con .hm-single-bx{
        display: block;
        position: relative;
        width: fit-content;
    }
    .hm-number-count .hm-main-wrap-count .hm-flex-con .hm-single-bx::after{
        content: none;
        width: 0;
        height: 50%;
        position: absolute;
        border: 1px solid #E0E0E0;
        top: 50%;
        transform: translateY(-50%);
        right: -65%;
    }
    .hm-number-count .hm-main-wrap-count .hm-flex-con .hm-single-bx:last-child:after{
        content: none;
    }
    
    .hm-number-count .hm-main-wrap-count .hm-flex-con .hm-single-bx .hm-num-n{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .hm-number-count .hm-main-wrap-count .hm-flex-con .hm-single-bx .hm-num-n .hm-nmbr{
        color: #2A2A2A;
        text-align: left;
        font-size: 60px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px; /* 56.667% */
        text-transform: capitalize;
        padding-bottom: 20px; 
    }
    .hm-number-count .hm-main-wrap-count .hm-flex-con .hm-single-bx .hm-num-n  .hm-tagline{
        color: #767676;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        text-align: center;
        line-height: 20px; /* 133.333% */
    }
    
    /* -------------------end of number section styles------------------------- */
    /* start image-text flex section */
    
    .hm-imgtxt-flex .hm-itf-main{
        display: none;
        padding: 30px 25px;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        text-align: center;
    }
    .hm-imgtxt-flex .hm-itf-main .hm-itf-left, .hm-itf-right{
        width: 100%;
    }
    .hm-imgtxt-flex .hm-itf-main .hm-itf-left, .hm-itf-right img{
        width: 100%;
        width: -webkit-fill-available;
        height: 100%;
        object-fit: cover;
    }
    .hm-imgtxt-flex .hm-itf-main .hm-itf-left .hm-itf-tgln{
        color: #727272;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px; /* 166.667% */
        text-transform: capitalize;
        padding-bottom: 14px;
    }
    .hm-imgtxt-flex .hm-itf-main .hm-itf-left .hm-itf-boldtxt{
        color: #444;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 133.333% */
        font-variant: small-caps;
        width: 100%;
        padding: 0;
        margin: 0;
        padding-bottom: 32px;
    
    }
    .hm-imgtxt-flex .hm-itf-main .hm-itf-left .-hm-itf-para .hm-itf-common{
        color: #767676;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
    }
    .hm-imgtxt-flex .hm-itf-main .hm-itf-left .-hm-itf-para .hm-itf-ul{
        list-style: none;
        padding: 0;
        color: #585858;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
    .hm-imgtxt-flex .hm-itf-main .hm-itf-left .hm-itf-btn{
        margin-top: 26px;
        display: flex;
        padding: 10px 24px;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        background: #E53F71;
        border: none;
        margin: 0 auto;
        margin-bottom: 30px;
    }
    .hm-imgtxt-flex .hm-itf-main .hm-itf-left .hm-itf-btn:hover{
        background:#2a6edd;
    }
    
    .gallery-section .main-div-gallery .gallery-header{
        padding-top: 60px;
        padding-bottom: 40px;
    }
    .gallery-section .main-div-gallery .gallery-header .big-header{
        color: #000;
        font-family: Montserrat;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px; /* 106.25% */
        font-variant: small-caps;
        display: block;
        text-align: center;
    }
    .gallery-section .main-div-gallery .gallery-header .small-tg-gal{
        color: #767676;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        text-transform: capitalize;
        padding-top: 16px;
        display: block;
    }
    .gallery-section .main-div-gallery .gallery-body{
        padding-left: 25px;
        padding-right: 25px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    
    .gallery-section .main-div-gallery .gallery-body img{
        width: 30%;
        margin-bottom: 24px;
    }
    
    .gallery-section .main-div-gallery .button-for-gal button{
        display: flex;
        padding: 10px 24px;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        background: #E53F71;
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        text-transform: capitalize;
        border: none;
        align-content: center;
        justify-content: center;
        width: fit-content;
        margin: 0 auto;
        margin-bottom: 60px;
        margin-top: 20px;
    }
    
    
    .EvntBottom .background{
        position: relative;
    }
    .EvntBottom .background img{
        width: 100%;
        object-fit: cover;
    }
    .EvntBottom .background .background-txt{
        position: absolute;
        top: 0;
        color: #fff;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 25px;
        text-align: center;
    }
    
    .EvntBottom .background .background-txt .event-hdr{
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px; /* 106.25% */
        text-transform: capitalize;
        padding-bottom: 10px;
    }
    .EvntBottom .background .background-txt p{
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-bottom: 32px;
    }
    .EvntBottom .background .background-txt button{
        display: flex;
        padding: 10px 24px;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        background: #E53F71;
        box-shadow: 0px 2px 70px 0px rgba(229, 63, 113, 0.60);
        border: none;
        width: fit-content;
        margin: 0 auto;
    }
}

@media (min-width:1200px){
    .hm-number-count{
        border-radius: 6px;
        display: block;
        width: 100%;
        background: #FAFAFA;
        box-shadow: 0px 4px 74px 0px rgba(0, 0, 0, 0.08);
    }
    .hm-number-count .hm-main-wrap-count .hm-flex-con{
        display: flex;
        padding: 2.167vw 10.915vw;
        justify-content:space-between;
        align-items: center;
        gap: 3.125vw;
        flex-direction: row;
    }
    .hm-number-count .hm-main-wrap-count .hm-flex-con .hm-single-bx{
        display: block;
        position: relative;
        width: fit-content;
    }
    .hm-number-count .hm-main-wrap-count .hm-flex-con .hm-single-bx::after{
        content: '';
        width: 0;
        height: 50%;
        position: absolute;
        border: 1px solid #E0E0E0;
        top: 50%;
        transform: translateY(-50%);
        right: -65%;
    }
    .hm-number-count .hm-main-wrap-count .hm-flex-con .hm-single-bx:last-child:after{
        content: none;
    }

    .hm-number-count .hm-main-wrap-count .hm-flex-con .hm-single-bx .hm-num-n{
        display: flex;
        flex-direction: column;
    }
    .hm-number-count .hm-main-wrap-count .hm-flex-con .hm-single-bx .hm-num-n .hm-nmbr{
        color: #2A2A2A;
        text-align: left;
        font-family: Montserrat;
        font-size: 4.167vw;
        font-style: normal;
        font-weight: 700;
        line-height: 4.167vw; /* 42.5% */
        text-transform: capitalize;
        /* padding-bottom: 20px; */
    }
    .hm-number-count .hm-main-wrap-count .hm-flex-con .hm-single-bx .hm-num-n .hm-nmbr .hm-tagline{
        color: #767676;
        font-family: Inter;
        font-size: 0.781vw;
        font-style: normal;
        font-weight: 500;
        line-height: 0.781vw; /* 133.333% */
    }

    /* ----------------------------end number section--------------------- */
    /* start image-text flex section */
    .hm-imgtxt-flex .hm-itf-main{
        display: flex;
        padding: 80px 156px;
        flex-direction: row;
        align-items: flex-start;
        width: 100%;
        column-gap: 72px;
        text-align: left;
        justify-content: space-between;
    }
    .hm-imgtxt-flex .hm-itf-main .hm-itf-left, .hm-itf-right{
        width: 50%;
    }
    .hm-imgtxt-flex .hm-itf-main .hm-itf-left, .hm-itf-right img{
        width: 100%;
        width: 38vw;
        height: 100%;
        object-fit: cover;
    }
    .hm-imgtxt-flex .hm-itf-main .hm-itf-left .hm-itf-tgln{
        color: #727272;
        font-family: 'Inter';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 166.667% */
        text-transform: uppercase;
        padding-bottom: 12px;
    }
    .hm-imgtxt-flex .hm-itf-main .hm-itf-left .hm-itf-boldtxt{
        color: #444;
        font-family: 'Montserrat';
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 133.333% */
        text-transform: uppercase;
        width: 80%;
        padding: 0;
        margin: 0;
        padding-bottom: 32px;

    }
    .hm-imgtxt-flex .hm-itf-main .hm-itf-left .-hm-itf-para .hm-itf-common{
        color: #767676;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        width: 85%;
    }
    .hm-imgtxt-flex .hm-itf-main .hm-itf-left .-hm-itf-para .hm-itf-ul{
        list-style: none;
        padding: 0;
        color: #585858;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
    .hm-imgtxt-flex .hm-itf-main .hm-itf-left .hm-itf-btn{
        margin: 0;
        margin-top: 26px;
        display: flex;
        padding: 10px 24px;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        background: #E53F71;
        border: none;
        border: none;
        margin-bottom: 0px;
    }
    .hm-imgtxt-flex .hm-itf-main .hm-itf-left .hm-itf-btn:hover{
        background:#2a6edd;
    }


    .gallery-section .main-div-gallery .gallery-header{
        padding-top: 80px;
        padding-bottom: 40px;
    }
    .gallery-section .main-div-gallery .gallery-header .big-header{
        color: #000;
        font-family: Montserrat;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px; /* 106.25% */
        font-variant: small-caps;
        display: block;
        text-align: center;
    }
    .gallery-section .main-div-gallery .gallery-header .small-tg-gal{
        color: #767676;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        text-transform: capitalize;
        padding-top: 16px;
        display: block;
    }
    .gallery-section .main-div-gallery .gallery-body{
        padding-left: 156px;
        padding-right: 156px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 40px;
    }
    
    .gallery-section .main-div-gallery .gallery-body img{
        width: 320px;
        margin-bottom: 40px;
    }
    
    .gallery-section .main-div-gallery .button-for-gal button{
        display: flex;
        padding: 10px 24px;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        background: #E53F71;
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        text-transform: capitalize;
        border: none;
        align-content: center;
        justify-content: center;
        width: fit-content;
        margin: 0 auto;
        margin-bottom: 60px;
        margin-top: 20px;
    }
    
    
    .EvntBottom .background{
        position: relative;
    }
    .EvntBottom .background img{
        width: 100%;
        object-fit: cover;
    }
    .EvntBottom .background .background-txt{
        position: absolute;
        top: 0;
        color: #fff;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 25px 355px;
        text-align: center;
    }
    
    .EvntBottom .background .background-txt .event-hdr{
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px; /* 106.25% */
        text-transform: capitalize;
        padding-bottom: 16px;
    }
    .EvntBottom .background .background-txt p{
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        margin-bottom: 32px;
    }
    .EvntBottom .background .background-txt button{
        display: flex;
        padding: 10px 24px;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        background: #E53F71;
        box-shadow: 0px 2px 70px 0px rgba(229, 63, 113, 0.60);
        border: none;
        width: fit-content;
        margin: 0 auto;
    }
}