.paymentFail-Main {
    height: 100vh;
    /* background: linear-gradient(90deg, rgba(229, 63, 113, 0.39) -25.66%, rgba(44, 0, 69, 0.39) 121.78%); */
}

.bg {
    display: block;
    width: 100%;
    position: relative;
}

.bg img {
    display: block;
    width: 100%;
    object-fit: cover;
    position: relative;

}

.abslTxt {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px 24px;
    transform: unset;
    text-align: center;
    width: 100%;
}

.lg {
    height: fit-content;
}

.lg img {
    width: 60px;
    margin: 0;
}

.BoldTxt {
    display: block;
    margin: 287px 0px 20px;
    color: #F0F0F0;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-variant: small-caps;
    padding: 0px 55px;
}

.SmlText {
    display: block;
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 200% */
    margin-bottom: 32px;
}

.abslTxt a {
    text-decoration: none;
}

.backToHome {
    display: flex;
    padding: 12px 24px;
    align-items: center;
    max-width: 200px;
    gap: 8px;
    border-radius: 4px;
    background: #E53F71;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    justify-content: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
    margin: 0 auto;
    text-decoration: none;
    border: none;
}

.line-btm {
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    height: 2px;
    background: var(--Gradient-1, rgba(255, 255, 255, 0.12));
}

@media (min-width: 1200px) {
    .paymentFail-Main {
        height: unset;
        /* background: linear-gradient(90deg, rgba(229, 63, 113, 0.39) -25.66%, rgba(44, 0, 69, 0.39) 121.78%); */
    }

    .bg {
        display: block;
        width: 100%;
        position: relative;
        height: unset;
    }

    .bg img {
        display: block;
        width: 100%;
        object-fit: cover;
        position: relative;

    }

    .abslTxt {
        display: block;
        position: absolute;
        top: 0.73020833333333334vw;
        left: 10vw;
        transform: unset;
        text-align: left;
        width: 35vw;
        padding: 0;
    }

    .lg {
        height: fit-content;
        width: fit-content;
        display: block;
    }

    .lg img {
        width: 3.6458333333333335vw;
        margin: 0 auto;
    }

    .BoldTxt {
        display: block;
        margin: 13.28125vw 0px 1.3020833333333333vw;
        color: #F0F0F0;
        text-align: left;
        leading-trim: both;
        text-edge: cap;
        font-family: Montserrat;
        font-size: 2.8645833333333335vw;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 15vw;
        padding: 0;
    }

    .SmlText {
        display: block;
        color: #FFF;
        text-align: left;
        leading-trim: both;
        text-edge: cap;
        font-family: Inter;
        font-size: 1.3020833333333333vw;
        font-style: normal;
        line-height: 2.0833333333333335vw; /* 200% */
        margin-bottom: 2.0833333333333335vw;
    }

    .backToHome {
        display: flex;
        padding: 0.78125vw 1.5625vw;
        align-items: center;
        max-width: 13.020833333333334vw;
        gap: 8px;
        border-radius: 4px;
        background: #E53F71;
        color: #FFF;
        text-align: center;
        font-family: Inter;
        justify-content: left;
        font-size: 0.9114583333333334vw;
        font-style: normal;
        font-weight: 500;
        line-height: 1.3020833333333333vw; /* 142.857% */
        text-transform: capitalize;
        margin: unset;
        border: none;
    }

    .line-btm {
        position: absolute;
        top: 5.208333333333333vw;
        left: 0;
        width: 100%;
        height: 0.13020833333333334vw;
        background: var(--Gradient-1, rgba(255, 255, 255, 0.12));
    }
}
