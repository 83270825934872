
.admin-dashboard .main-content{
    padding: 60px 24px;
}

.billInforSp{
    color: #000;
    text-align: left;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 58.333% */
    padding-bottom: 24px;  
    display: block;  
}
.admin-dashboard .main-content .checkout-main-wrapper{
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }
  .admin-dashboard .main-content .checkout-main-wrapper .leftSide-check{
    width: 100%;
    padding: 40px;
    border-radius: 6px;
    background: #F9F9F9;
  }
  .personDetail .lineSingle{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .personDetail .lineSingle span{
    display: block;
  }
  .personDetail .lineSingle span:first-child{
    color: #393939;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 107.692% */
  }
  .personDetail .lineSingle span:last-child{
    color: #383838;
    text-align: right;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 107.692% */
  }
  .personDetail .lineSingle:last-child{
    padding-bottom: 24px;
    border-bottom: 2px solid #E7E7E7;
    margin-bottom: 24px;
  }
  
  .tSize .lineSingle{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .tSize .lineSingle span{
    display: block;
  }
  .tSize .lineSingle span:first-child{
    color: #7D7D7D;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
  }
  .tSize .lineSingle span:last-child{
    color: #383838;
    text-align: right;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 107.692% */
  }
  .tSize .lineSingle:last-child{
    padding-bottom: 24px;
    border-bottom: 2px solid #E7E7E7;
    margin-bottom: 24px;
  }
  
  .registData .lineSingle{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .registData .lineSingle span{
    display: block;
  }
  .registData .lineSingle span:first-child{
    color: #7D7D7D;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 100% */
  }
  .registData .lineSingle span:last-child{
    color: #383838;
    text-align: right;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 107.692% */
  }
  .registData .lineSingle:last-child{
    padding-bottom: 24px;
    border-bottom: 2px solid #E7E7E7;
    margin-bottom: 24px;
  }
  
  .totDonation .lineSingle{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .totDonation .lineSingle span{
    display: block;
  }
  .totDonation .lineSingle span:first-child{
    color: #E53F71;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 77.778% */
  }
  .totDonation .lineSingle span:last-child{
    color: #E54E7B;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 77.778% */
  }
  .admin-dashboard .main-content .checkout-main-wrapper .rgtSide-check{
    width: 100%;
    padding: 0px;
    border-radius: 6px;
  }
  .cardInCashMain .flx-crd{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 24px;
    border-radius: 6px;
    background: #F7F7F7;
    row-gap: 24px;
    flex-direction: column;
  }
  .cardInCashMain .flx-crd .lft{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .cardInCashMain .flx-crd .lft span:first-child{
    display: block;
    color: #282828;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 87.5% */
    margin-bottom: 12px;
  }
  .cardInCashMain .flx-crd .lft span:last-child{
    display: block;
    color: #929292;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
  }
  
  .payOptions{
    margin-top: 24px;
    display: flex;
    padding: 40px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    border-radius: 6px;
    background: #F5F5F5;
  }
  
  
  .payOptions .payOptionline{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 46px;
    align-items: center;
    color: #A1A1A1;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
  
  .para-def-pay p{
    color: #A1A1A1;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .payButtonCheckout{
    display: flex;
    padding: 12px 14px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    background: #E53F71;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
    width: 100%;
    border: none;
    transition: all 0.5s;
  }
  
  .payButtonCheckout:hover{
    background: #3c2f33;
    transition: all 0.5s;
  }
  
.popupWindow{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-flex;
    padding: 40px 32px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    border-radius: 12px;
    background: #FFF;
    width: 480px;
  }
  .popupWindow .popup-content img{
    display: block;
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  .popUpThanks{
    color: #393939;
    text-align: center;
    display: block;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px; /* 83.333% */
    text-transform: capitalize;
    margin-bottom: 12px;
  }
  .tagThanks{
    color: #7D7D7D;
    text-align: center;
    display: block;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 153.846% */
    margin-bottom: 40px;
  }
  .spcTag{
    color: #808080;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    width: fit-content;
    margin: 0 auto;
    display: block;
    margin-bottom: 24px;
  }
  .popup-content p{
    color: #A1A1A1;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
  }
  .sendPop{
    display: flex;
    padding: 12px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    background: #E53F71;
    width: 100%;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
  }

  .tLableTxt{
    display: flex;
    padding: 20px 24px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    background-color: rgba(168, 63, 229, 0.285);
    margin-bottom: 40px;
  }
  .tLableTxt span{
    color: #A83FE5;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 87.5% */
    text-transform: capitalize;
  }
  .grpCat .lineSingle span:first-child{
    color: #7D7D7D;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
  }
  .grpCat .lineSingle span:last-child {
    color: #383838;
    text-align: right;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 107.692% */
  }
  
  .quntiLbl .lineSingle span:first-child{
    color: #7D7D7D;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
  }
  
  .quntiLbl .lineSingle span:last-child{
    color: #7D7D7D;
    text-align: right;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
  }
  
  .tDatalbls .lineSingle span:first-child{
    color: #383838;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 107.692% */
  }
  
  .tDatalbls .lineSingle span:last-child{
    color: #383838;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 107.692% */
  }
  
  .donGrp .lineSingle span:first-child{
    color: #A83FE5;
    text-align: right;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 77.778% */
  }
  
  .donGrp .lineSingle span:last-child{
    color: #A83FE5;
    text-align: right;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 77.778% */
  }
  .payGrpCheckout{
    display: flex;
    padding: 12px 14px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    background: #A83FE5;;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
    width: 100%;
    border: none;
    transition: all 0.5s;
  }
  
  .payGrpCheckout:hover{
    background: #3c2f33;
    transition: all 0.5s;
  }
  
  .sendGrpPop{
    display: flex;
    padding: 12px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    background: #A83FE5;;
    width: 100%;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
  }
.grpPay{
  background-color: #A83FE5;
}
@media (min-width:1200px){
    .admin-dashboard .main-content{
        padding: 80px 156px;
    }
    
    .billInforSp{
        color: #000;
        text-align: left;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 58.333% */
        padding-bottom: 24px;  
        display: block;  
    }
.admin-dashboard .main-content .checkout-main-wrapper{
    display: flex;
    flex-direction: row;
    column-gap: 64px;
  }
  .admin-dashboard .main-content .checkout-main-wrapper .lef{
    width: 50%;
  }
  .admin-dashboard .main-content .checkout-main-wrapper .leftSide-check{
    
    padding: 40px;
    border-radius: 6px;
    background: #F9F9F9;
  }
  .personDetail .lineSingle{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .personDetail .lineSingle span{
    display: block;
  }
  .personDetail .lineSingle span:first-child{
    color: #393939;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 107.692% */
  }
  .personDetail .lineSingle span:last-child{
    color: #383838;
    text-align: right;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 107.692% */
  }
  .personDetail .lineSingle:last-child{
    padding-bottom: 24px;
    border-bottom: 2px solid #E7E7E7;
    margin-bottom: 24px;
  }
  
  .tSize .lineSingle{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .tSize .lineSingle span{
    display: block;
  }
  .tSize .lineSingle span:first-child{
    color: #7D7D7D;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
  }
  .tSize .lineSingle span:last-child{
    color: #383838;
    text-align: right;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 107.692% */
  }
  .tSize .lineSingle:last-child{
    padding-bottom: 24px;
    border-bottom: 2px solid #E7E7E7;
    margin-bottom: 24px;
  }
  
  .registData .lineSingle{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .registData .lineSingle span{
    display: block;
  }
  .registData .lineSingle span:first-child{
    color: #7D7D7D;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 100% */
  }
  .registData .lineSingle span:last-child{
    color: #383838;
    text-align: right;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 107.692% */
  }
  .registData .lineSingle:last-child{
    padding-bottom: 24px;
    border-bottom: 2px solid #E7E7E7;
    margin-bottom: 24px;
  }
  
  .totDonation .lineSingle{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .totDonation .lineSingle span{
    display: block;
  }
  .totDonation .lineSingle span:first-child{
    color: #E53F71;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 77.778% */
  }
  .totDonation .lineSingle span:last-child{
    color: #E54E7B;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 77.778% */
  }
  .admin-dashboard .main-content .checkout-main-wrapper .rgtSide-check{
    width: 50%;
    padding: 0px;
    border-radius: 6px;
  }
  .cardInCashMain .flx-crd{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 24px;
    border-radius: 6px;
    background: #F7F7F7;
    flex-direction: row;
  }
  .cardInCashMain .flx-crd .lft{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .cardInCashMain .flx-crd .lft span:first-child{
    display: block;
    color: #282828;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 87.5% */
    margin-bottom: 12px;
  }
  .cardInCashMain .flx-crd .lft span:last-child{
    display: block;
    color: #929292;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
  }
  
  .payOptions{
    margin-top: 24px;
    display: flex;
    padding: 40px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    border-radius: 6px;
    background: #F5F5F5;
  }
  
  
  .payOptions .payOptionline{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 46px;
    align-items: center;
    color: #A1A1A1;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
  
  .para-def-pay p{
    color: #A1A1A1;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .payButtonCheckout{
    display: flex;
    padding: 12px 14px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    background: #E53F71;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
    width: 100%;
    border: none;
    transition: all 0.5s;
  }
  
  .payButtonCheckout:hover{
    background: #3c2f33;
    transition: all 0.5s;
  }
  .tLableTxt{
    display: flex;
    padding: 20px 24px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    background-color: rgba(168, 63, 229, 0.285);
    margin-bottom: 20px;
  }
  .tLableTxt span{
    color: #A83FE5;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 87.5% */
    text-transform: capitalize;
  }
  .grpCat .lineSingle span:first-child{
    color: #7D7D7D;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
  }
  .grpCat .lineSingle span:last-child {
    color: #383838;
    text-align: right;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 107.692% */
  }
  
  .quntiLbl .lineSingle span:first-child{
    color: #7D7D7D;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
  }
  
  .quntiLbl .lineSingle span:last-child{
    color: #7D7D7D;
    text-align: right;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
  }
  
  .tDatalbls .lineSingle span:first-child{
    color: #383838;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 107.692% */
  }
  
  .tDatalbls .lineSingle span:last-child{
    color: #383838;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 107.692% */
  }
  
  .donGrp .lineSingle span:first-child{
    color: #A83FE5;
    text-align: right;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 77.778% */
  }
  
  .donGrp .lineSingle span:last-child{
    color: #A83FE5;
    text-align: right;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 77.778% */
  }
  .payGrpCheckout{
    display: flex;
    padding: 12px 14px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    background: #A83FE5;;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
    width: 100%;
    border: none;
    transition: all 0.5s;
  }
  
  .payGrpCheckout:hover{
    background: #3c2f33;
    transition: all 0.5s;
  }
  
  .sendGrpPop{
    display: flex;
    padding: 12px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    background: #A83FE5;;
    width: 100%;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
  }
  .grpPay{
    background-color: #A83FE5;
  }
}