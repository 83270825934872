.summer-wrap {
    padding: 0px 24px 60px;
}

.logo-pg {
    margin: 0 auto;
    width: fit-content;
    display: flex;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.book-confirm {
    display: flex;
    padding: 60px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    border-radius: 12px 12px 0px 0px;
    background: #FFEDF3;
}

.booking-confirm {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 116.667% */
    text-transform: capitalize;
}

.para-nor {
    color: #7D7D7D;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
}

.para-bold {
    color: #4E4E4E;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
}

.bill-sum-wrapp {
    padding: 40px 24px;
}

.bill-head {
    padding-bottom: 40px;
    border-bottom: 1px solid #263238;
}

.date {
    color: #999;
    text-align: left;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 116.667% */
    text-transform: capitalize;
    display: block;
    padding-bottom: 24px;
}

.BillHead {
    color: #000;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 70% */
    text-transform: capitalize;
    display: block;
    padding-bottom: 24px;
}

.BookingID {
    display: block;
    padding-bottom: 24px;
    color: #A1A1A1;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 93.333% */
    text-transform: capitalize;
}

.flx-div {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #263238;
}

.sin {
    display: flex;
    justify-content: space-between;
    width: 100%;

}

.cml-txt {
    color: #393939;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 107.692% */
}

.big-txt {
    color: #383838;
    text-align: right;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 107.692% */
}

.bill-sum-wrapp {
    background: #F9F9F9;
}

.sin-m {
    padding-top: 32px;
    color: #7D7D7D;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
    display: block;
}

.sin-b {
    padding-bottom: 32px;
    color: #383838;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 93.333% */
}

.pay-dil {
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid #263238;
    row-gap: 12px;
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
    border-bottom: 1px solid #263238;
}

.sin-col {
    display: flex;
    justify-content: space-between;

}

.reg-n {
    display: block;
    color: #7D7D7D;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 87.5% */

}

.reg-c {
    color: #383838;
    text-align: right;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 87.5% */
}

.total {
    display: flex;
    justify-content: space-between;
    padding: 32px 0px;
    color: #E53F71;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 87.5% */
    border-bottom: 1px solid #263238;
}

.scann-div {
    padding: 24px 0px;
    border-top: 1px solid #263238;
    border-bottom: 1px solid #263238;

}

.text-head {
    color: #383838;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 160% */
    display: block;
    padding-bottom: 12px;
}

.para-btm {
    color: #383838;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    display: block;
    padding-bottom: 16px;
}

.normal-pp {
    color: #4F4F4F;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    display: block;
    padding: 24px 0px;
    padding-bottom: 40px;
}

.thnks {
    color: #383838;
    text-align: left;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 87.5% */
    display: block;
    padding-bottom: 10px;
}

.btm-nm {
    color: #383838;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 153.846% */
    display: block;
}

.dwn {
    display: flex;
    padding: 12px 40px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
    border-radius: 4px;
    background: #E53F71;
    margin: 80px 0px 40px;
    width: 100%;
}

.dwnsp {
    background: #A83FE5;
}

.cpy {
    color: #4E4E4E;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    line-height: 16px; /* 123.077% */
}

.flx-ftr {
    display: flex;
    grid-gap: 12px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border: none;
    margin-top: 32px;
}

.nav-btn {
    background: transparent;
    color: #E53F71;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 123.077% */
    text-decoration-line: underline;
    border: none;
    color: #E53F71;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 123.077% */
    text-decoration-line: underline;

}

.spc-chng {
    background: #F8EDFF;
}

.t-lbl {
    margin: 32px 0px;
    display: flex;
    padding: 20px 24px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    width: 100%;
    background-color: #a83fe544;
    color: #a83fe5;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    border-radius: 4px;
    font-weight: 600;
    line-height: 14px; /* 87.5% */
    text-transform: capitalize;
}

.flx-main {
    display: block;
    border-bottom: 1px solid #263238;
    margin-top: 20px;
}

.flx-main:last-child {
    border: none;
}

.sml-fl {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 20px;
}

.tsp {
    color: #A83FE5;
}

@media (min-width: 1200px) {
    .summer-wrap {
        padding: 0px 156px 80px;
    }

    .logo-pg {
        margin: 0 auto;
        width: fit-content;
        display: flex;
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .book-confirm {
        display: flex;
        padding: 60px 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        border-radius: 12px 12px 0px 0px;
        background: #FFEDF3;
    }

    .booking-confirm {
        color: #000;
        width: 1128px;
        text-align: center;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 116.667% */
        text-transform: capitalize;
    }

    .para-nor {
        color: #7D7D7D;
        text-align: center;
        display: block;
        width: 48%;
        leading-trim: both;
        text-edge: cap;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 166.667% */
    }

    .para-bold {
        color: #4E4E4E;
        width: 1128px;
        display: block;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 166.667% */
    }

    .bill-sum-wrapp {
        padding: 60px 40px;
        margin-bottom: 60px;
    }

    .bill-head {
        padding-bottom: 40px;
        border-bottom: 1px solid #263238;
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .date {
        color: #999;
        text-align: left;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px; /* 116.667% */
        text-transform: capitalize;
        display: block;
        padding-bottom: 24px;
    }

    .BillHead {
        color: #000;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 14px; /* 70% */
        text-transform: capitalize;
        display: block;
        padding-bottom: 24px;
    }

    .BookingID {
        display: block;
        padding-bottom: 24px;
        color: #A1A1A1;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px; /* 93.333% */
        text-transform: capitalize;
    }

    .flx-div {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding-top: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #263238;
    }

    .sin {
        display: flex;
        justify-content: space-between;
        width: 100%;

    }

    .cml-txt {
        color: #393939;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 107.692% */
    }

    .big-txt {
        color: #383838;
        text-align: right;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 14px; /* 107.692% */
    }

    .div-box {


    }

    .sin-m {
        padding-top: 32px;
        color: #7D7D7D;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px; /* 100% */
        display: block;
    }

    .sin-b {
        padding-bottom: 32px;
        color: #383838;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px; /* 93.333% */
    }

    .pay-dil {
        margin-top: 32px;
        padding-top: 32px;
        border-top: 1px solid #263238;
        row-gap: 12px;
        display: flex;
        flex-direction: column;
        padding-bottom: 32px;
        border-bottom: 1px solid #263238;
    }

    .sin-col {
        display: flex;
        justify-content: space-between;

    }

    .reg-n {
        display: block;
        color: #7D7D7D;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px; /* 87.5% */

    }

    .reg-c {
        color: #383838;
        text-align: right;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px; /* 87.5% */
    }

    .total {
        display: flex;
        justify-content: space-between;
        padding: 32px 0px;
        color: #E53F71;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px; /* 87.5% */
        border-bottom: 1px solid #263238;
    }

    .scann-div {
        padding: 24px 0px;
        border-top: 1px solid #263238;
        border-bottom: 1px solid #263238;
        display: flex;
        width: 100%;
        justify-content: space-between;

    }

    .text-head {
        color: #383838;
        font-family: Inter;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 160% */
        display: block;
        padding-bottom: 12px;
    }

    .para-btm {
        color: #383838;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
        display: block;
        padding-bottom: 16px;
    }

    .normal-pp {
        color: #4F4F4F;
        leading-trim: both;
        text-edge: cap;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 171.429% */
        display: block;
        padding: 24px 0px;
        padding-bottom: 40px;
    }

    .thnks {
        color: #383838;
        text-align: left;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px; /* 87.5% */
        display: block;
        padding-bottom: 10px;
    }

    .btm-nm {
        color: #383838;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 153.846% */
        display: block;
        margin-bottom: 30px;
    }

    .dwn {
        display: flex;
        padding: 12px 40px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        text-transform: capitalize;
        border-radius: 4px;
        background: #E53F71;
        margin: 80px 0px 40px;
        width: 70%;
        margin: 0 auto;

    }

    .cpy {
        margin-top: 30px;
        display: block;
        color: #4E4E4E;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        text-align: center;
        font-weight: 500;
        line-height: 16px; /* 123.077% */
    }

    .flx-ftr {
        display: flex;
        grid-gap: 12px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        border: none;
        margin-top: 32px;
    }

    .nav-btn {
        background: transparent;
        color: #E53F71;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 123.077% */
        text-decoration-line: underline;
        border: none;
        color: #E53F71;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 123.077% */
        text-decoration-line: underline;

    }

    .flx-sp-0 {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .spc-chng {
        background: #F8EDFF;
    }

    .flx-main {
        display: block;
        border-bottom: 1px solid #263238;
        margin-top: 20px;
    }

    .flx-main:last-child {
        border: none;
    }

    .sml-fl {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 20px;
    }

    .tsp {
        color: #A83FE5;
    }

    .dwnsp {
        background: #A83FE5;
    }
}
