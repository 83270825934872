.loading {
    text-align: center;
    padding: 20%;
}

.loading > span {
    width: 5rem;
    height: 5rem;
}

@media (max-width: 768px) {
    .loading > div > svg {
        width: 100% !important;
        height: 100% !important;
    }
}
