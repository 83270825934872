.OurPartnerMain{
    padding: 60px 25px;
}

.OurPartnerMain .partner-header span{
    color: #000;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px; /* 106.25% */
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 60px;
    display: block;
}

.OurPartnerMain .partnerBody img{
    height: 60px;
    width: 60%;
    object-fit: contain;
}
.OurPartnerMain .slick-list {margin: 0 -18px;}
.OurPartnerMain .slick-slide> div {padding: 0 18px;}

.OurPartnerMain .slick-slide img{
    display: block;
    width: 80%;
    height: fit-content;
    background:transparent;
    object-fit: cover;
    height: 60px;
    width: 50%;
    object-fit: contain;
    margin: 0 auto;
}

@media (min-width:1200px){
    .OurPartnerMain{
        padding: 60px 25px; 
    }
    
    .OurPartnerMain .partner-header span{
        color: #000;
        font-family: Montserrat;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px; /* 106.25% */
        text-transform: uppercase;
        text-align: center;
        padding-bottom: 60px;
        display: block;
    }
    .Slider{
        padding-left: 200px;
        padding-right: 200px;
    }
    .OurPartnerMain .partnerBody img{
        height: 60px;
        width: 80%;
        object-fit: contain;
    }
    .OurPartnerMain .slick-list {margin: 0 auto;}
    .OurPartnerMain .slick-slide> div {padding: 0 auto;}
    .OurPartnerMain .slick-slide{
        height: 100%;
    }
}