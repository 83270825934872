.NotFoundMainWrapper{
    width: 100%;
    position: relative;
    z-index: 1;

}
.NotFoundMainWrapper img{
    width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: 1;
    position: relative;
}

.NotFoundMainWrapper .abslTxt{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    width: 100%;
    padding: 24px;
    text-align: center;
}
.NotFoundMainWrapper .abslTxt span{
    display: block;
}

.NotFoundMainWrapper .abslTxt .num{
    color: #FFF;
    font-family: Montserrat;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    
    padding-bottom: 32px;
}
.NotFoundMainWrapper .abslTxt .main{
    color: #F0F0F0;
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    
    font-variant: small-caps;
    padding-bottom: 20px;

}
.NotFoundMainWrapper .abslTxt .small{
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding: 0px 12px;
    padding-bottom: 32px;
    
}

.NotFoundMainWrapper .abslTxt button{
    display: flex;
    padding: 12px 24px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: #E53F71;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    width: fit-content;
    text-transform: capitalize;
    justify-content: center;
    margin: 0 auto;
    border: none;
}
@media (min-width:1200px){
    .NotFoundMainWrapper{
        width: 100%;
        position: relative;
        z-index: 1;
    
    }
    .NotFoundMainWrapper img{
        width: 100%;
        height: 100vh;
        object-fit: cover;
        z-index: 1;
        position: relative;
    }
    
    .NotFoundMainWrapper .abslTxt{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;
        width: 100%;
        padding: 0px 486px;
        text-align: center;
    }
    .NotFoundMainWrapper .abslTxt span{
        display: block;
    }
    
    .NotFoundMainWrapper .abslTxt .num{
        color: #FFF;
        font-family: Montserrat;
        font-size: 80px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        
        padding-bottom: 32px;
    }
    .NotFoundMainWrapper .abslTxt .main{
        color: #F0F0F0;
        font-family: Montserrat;
        font-size: 44px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        font-variant: small-caps;
        
        font-variant: small-caps;
        padding-bottom: 20px;
    
    }
    .NotFoundMainWrapper .abslTxt .small{
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 160% */
        padding-bottom: 32px;
        
    }
    
    .NotFoundMainWrapper .abslTxt button{
        display: flex;
        padding: 10px 24px;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        background: #E53F71;
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        text-transform: capitalize;
        justify-content: center;
        margin: 0 auto;
        border: none;
    }
}
