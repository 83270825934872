.main-wrapper-lpri{
    padding: 60px 24px;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}
.left .smallTagline{
    color: #727272;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    text-transform: uppercase;    
    display: block;
    padding-bottom: 12px;
}

.left .MainTAg{
    color: #444;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
    text-transform: uppercase;   
    display: block;
    padding-bottom: 32px;
}

.small-linePara{
    color: #767676;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */   
}
.leftParaRightImg{
    width: 100%;
}
.blueLine span{
    display: flex;
    width: 100%;
    padding: 80px 156px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    background: #3D0060;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 48px; /* 150% */
    text-transform: capitalize;
}
.hm-imgtxt-flex .rotateFlx{
    display: flex;
    padding: 60px 24px;
    flex-direction: column-reverse;
    align-items: flex-start;
    width: 100%;
    text-align: center;
    row-gap: 42px;
}
.eventBtnMain{
    display: flex;
    padding: 10px 24px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: #E53F71;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
    border: none;
    margin-top: 30px;
}
@media(min-width:1024px){
    .main-wrapper-lpri{
        padding: 60px 24px;
        display: flex;
        flex-direction: row;
        column-gap: 42px;
    }
    .left, .right{
        width: 50%;
    }
    .left .smallTagline{
        color: #727272;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 166.667% */
        text-transform: uppercase;    
        display: block;
        padding-bottom: 12px;
    }
    
    .left .MainTAg{
        color: #444;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 160% */
        text-transform: uppercase;
        display: block;
        padding-bottom: 32px;
    }
    
    .small-linePara{
        color: #767676;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */   
    }
    .leftParaRightImg{
        width: 100%;
    }
    
}

@media(min-width:1200px){
    .main-wrapper-lpri{
        padding:  5.20833vw 8.15625vw;
        display: flex;
        flex-direction: row;
        column-gap: 4.6875vw;
    }
    .left{
        width: 38%;
    }
    .right{
        width: 60%;
    }
    .left .smallTagline{
        color: #727272;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.30vw; /* 166.667% */
        text-transform: uppercase;    
        display: block;
        padding-bottom: 0.78125vw;
    }
    
    .left .MainTAg{
        color: #444;
        font-family: Montserrat;
        font-size: 1.5625vw;
        font-style: normal;
        font-weight: 700;
        line-height: 2.0833vw; /* 133.333% */
        font-variant: small-caps;
        padding-bottom: 2.0833vw;
    }
    
    .small-linePara{
        color: #767676;
        font-family: Inter;
        font-size: 0.9114vw;
        font-style: normal;
        font-weight: 400;
        line-height: 1.302vw; /* 142.857% */   
    }
    .leftParaRightImg{
        width: 100%;
    }
    .blueLine span{
        display: flex;
        padding: 5.208333333333333vw 10.15625vw;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:  3.90625vw;
        align-self: stretch;
        background: #3D0060;
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 2.6041666666666665vw;
        font-style: normal;
        font-weight: 800;
        line-height: 2.6041666666666665vw; /* 120% */
        text-transform: capitalize;
    } 
    .hm-imgtxt-flex .rotateFlx{
        display: flex;
        padding: 80px 156px;
        flex-direction: row-reverse;
        align-items: flex-start;
        width: 100%;
        column-gap: 72px;
        text-align: left;
        justify-content: space-between;
    }
    .eventBtnMain{
        display: flex;
        padding: 10px 24px;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        background: #E53F71;
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        text-transform: capitalize;
        border: none;
        margin-top: 40px;
    }
}