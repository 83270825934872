/* Header.css */
.custom-navbar {
    background-color: transparent !important; 
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    border-bottom: 2px solid var(--Gradient-1, rgba(255, 255, 255, 0.12));
    background-color: #08080886 !important;
}
.logo{
    height: 45px;
}
.menu-item {
    position: relative;
    width: max-content;
    transition: all 0.7s;
}

.active-pg::before{
    content: '';
    position: absolute;
    width: 85%;
    height: 2px;
    bottom: -1.6vw;
    border-radius: 50px;
    background: #E53F71;
    transition: all 0.7s;
}
.menu-item:hover::before {
    content: '';
    position: absolute;
    width: 85%;
    height: 2px;
    bottom: -1.6vw;
    border-radius: 50px;
    background: #E53F71;
    transition: all 0.7s;
}
.right-css-nv.lnv{
    color: black !important;
}
/* Center the navigation items */
.navbar-nav {
    margin: 0 auto; /* Center horizontally */
    display: flex;
    justify-content: center;
    align-items: center;
}
.mdl-set{
    width: 100%;
}
.rgt-set{
    width: max-content; 
}
.rgt-set a{
    display: block;
    width: max-content;
}
.follow-us{
    pointer-events: none;
}
.navbar-dark .navbar-nav .nav-link {
    color: #ffffff  !important;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 100% */
    text-transform: capitalize;
}
.slider-overlay {
    position: relative;
    width: 100%;
    overflow: hidden;
    background: linear-gradient(87deg, rgba(0, 0, 0, 0.40) 2.28%, rgba(59, 2, 19, 0.03) 97.72%);
}
.container-hdr{
    display: block;
}
.slick-slide img{
    display: block;
    width: 100%;
    height: 100vh;
    /* background: linear-gradient(87deg, rgba(0, 0, 0, 0.40) 2.28%, rgba(59, 2, 19, 0.03) 97.72%); */
    object-fit: cover;
}
.container-hdr{ 
    display: block;
    position: absolute;
    top: 200px;
    z-index: 9;
    width: 350px !important;
    color: #fff;
    /* left: 50%; */
    padding-left: 10.8vw;
    text-align: left;
}


.container-hdr .sp-span{
    color: #FFF;
    font-family: Inter;
    font-size:  14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 183.333% */
    padding-bottom:  16px;
    display: block;
}
.container-hdr .highlighted-word{
    color: #e53f71;
    font-family: Montserrat;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    text-transform: uppercase;
}
.container-hdr h1{
    color: #FFF;
    text-align: left;
    font-family: Montserrat;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 100% */
    text-transform: uppercase;
    padding: 0;
    padding-bottom: 24px;
    margin: 0;
}
.container-hdr p{
    color: #FFF;
    leading-trim: both;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    margin-bottom: 60px;
}
.container-hdr .button-sec-hdr{
    display: flex;
    flex-direction: column;
    column-gap: 20px;
    row-gap: 20px;
}
.container-hdr.button-sec-hdr button{
    background-color: #E53F71;
    color: #E53F71;
    display: flex;
    padding: 12px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}
.container-hdr .button-sec-hdr .fst, .Snd {
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 142.857% */
    text-transform: capitalize;
    background-color: #E53F71;
    outline: none;
    border: none;
    display: flex;
    padding: 10px 20px;
    align-items: center;
    gap: 0;
    display: flex;
    padding: 12px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}
.container-hdr .button-sec-hdr .fst, .Snd svg{
    width: 24px;
}

@media (min-width:768px){
    .custom-navbar {
        background-color: transparent !important; 
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 99;
        border-bottom: 2px solid var(--Gradient-1, rgba(255, 255, 255, 0.12));
        background-color: #08080886 !important;
    }
    .logo{
        height: 45px;
    }
    .menu-item {
        position: relative;
        width: max-content;
        transition: all 0.7s;
    }
    
    .active-pg::before{
        content: '';
        position: absolute;
        width: 85%;
        height: 2px;
        bottom: -1.6vw;
        border-radius: 50px;
        background: #E53F71;
        transition: all 0.7s;
    }
    .menu-item:hover::before {
        content: '';
        position: absolute;
        width: 85%;
        height: 2px;
        bottom: -1.6vw;
        border-radius: 50px;
        background: #E53F71;
        transition: all 0.7s;
    }
    .right-css-nv.lnv{
        color: black !important;
    }
    /* Center the navigation items */
    .navbar-nav {
        margin: 0 auto; /* Center horizontally */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .mdl-set{
        width: 100%;
    }
    .rgt-set{
        width: max-content; 
    }
    .rgt-set a{
        display: block;
        width: max-content;
    }
    .follow-us{
        pointer-events: none;
    }
    .navbar-dark .navbar-nav .nav-link {
        color: #ffffff  !important;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px; /* 100% */
        text-transform: capitalize;
    }
    .slider-overlay {
        position: relative;
        width: 100%;
        overflow: hidden;
        background: linear-gradient(87deg, rgba(0, 0, 0, 0.40) 2.28%, rgba(59, 2, 19, 0.03) 97.72%);
    }
    .container-hdr{
        display: block;
    }
    .slick-slide img{
        display: block;
        width: 100%;
        height: 100vh;
        background: linear-gradient(87deg, rgba(0, 0, 0, 0.40) 2.28%, rgba(59, 2, 19, 0.03) 97.72%);
        object-fit: cover;
    }
    .container-hdr{ 
        display: block;
        position: absolute;
        top: 200px;
        z-index: 9;
        width: 90% !important;
        color: #fff;
        /* left: 50%; */
        padding-left: 10.8vw;
        text-align: left;
    }
    
    
    .container-hdr .sp-span{
        color: #FFF;
        font-family: Inter;
        font-size:  14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 183.333% */
        padding-bottom:  16px;
        display: block;
    }
    .container-hdr .highlighted-word{
        color: #e53f71;
        font-family: Montserrat;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        text-transform: uppercase;
    }
    .container-hdr h1{
        color: #FFF;
        text-align: left;
        font-family: Montserrat;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px; /* 100% */
        text-transform: uppercase;
        padding: 0;
        padding-bottom: 24px;
        margin: 0;
    }
    .container-hdr p{
        color: #FFF;
        leading-trim: both;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        margin-bottom: 60px;
    }
    .container-hdr .button-sec-hdr{
        display: flex;
        flex-direction: column;
        column-gap: 20px;
        row-gap: 20px;
    }
    .container-hdr.button-sec-hdr button{
        background-color: #E53F71;
        color: #E53F71;
        display: flex;
        padding: 12px 14px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }
    .container-hdr .button-sec-hdr .fst, .Snd {
        color: #FFF;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 142.857% */
        text-transform: capitalize;
        background-color: #E53F71;
        outline: none;
        border: none;
        display: flex;
        padding: 10px 20px;
        align-items: center;
        gap: 0;
        display: flex;
        padding: 12px 14px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }
    .container-hdr .button-sec-hdr .fst, .Snd svg{
        width: 24px;
    }
}

@media (min-width:1024px){
    .custom-navbar {
        background-color: transparent !important; 
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 99;
        border-bottom: 2px solid var(--Gradient-1, rgba(255, 255, 255, 0.12));
        background-color: #08080886 !important;
    }
    .logo{
        height: 45px;
    }
    .menu-item {
        position: relative;
        width: max-content;
        transition: all 0.7s;
    }
    
    .active-pg::before{
        content: '';
        position: absolute;
        width: 85%;
        height: 2px;
        bottom: -1.6vw;
        border-radius: 50px;
        background: #E53F71;
        transition: all 0.7s;
    }
    .menu-item:hover::before {
        content: '';
        position: absolute;
        width: 85%;
        height: 2px;
        bottom: -1.6vw;
        border-radius: 50px;
        background: #E53F71;
        transition: all 0.7s;
    }
    .right-css-nv.lnv{
        color: black !important;
    }
    /* Center the navigation items */
    .navbar-nav {
        margin: 0 auto; /* Center horizontally */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .mdl-set{
        width: 100%;
    }
    .rgt-set{
        width: max-content; 
    }
    .rgt-set a{
        display: block;
        width: max-content;
    }
    .follow-us{
        pointer-events: none;
    }
    .navbar-dark .navbar-nav .nav-link {
        color: #ffffff  !important;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px; /* 100% */
        text-transform: capitalize;
    }
    .slider-overlay {
        position: relative;
        width: 100%;
        overflow: hidden;
        background: linear-gradient(87deg, rgba(0, 0, 0, 0.40) 2.28%, rgba(59, 2, 19, 0.03) 97.72%);
    }
    .container-hdr{
        display: block;
    }
    .slick-slide img{
        display: block;
        width: 100%;
        height: 100vh;
        background: linear-gradient(87deg, rgba(0, 0, 0, 0.40) 2.28%, rgba(59, 2, 19, 0.03) 97.72%);
        object-fit: cover;
    }
    .container-hdr{ 
        display: block;
        position: absolute;
        top: 200px;
        z-index: 9;
        width: 95% !important;
        color: #fff;
        /* left: 50%; */
        padding-left: 10.8vw;
        text-align: left;
    }
    
    
    .container-hdr .sp-span{
        color: #FFF;
        font-family: Inter;
        font-size:  14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 183.333% */
        padding-bottom:  16px;
        display: block;
    }
    .container-hdr .highlighted-word{
        color: #e53f71;
        font-family: Montserrat;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        text-transform: uppercase;
    }
    .container-hdr h1{
        color: #FFF;
        text-align: left;
        font-family: Montserrat;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px; /* 100% */
        text-transform: uppercase;
        padding: 0;
        padding-bottom: 24px;
        margin: 0;
    }
    .container-hdr p{
        color: #FFF;
        leading-trim: both;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 157.143% */
        margin-bottom: 60px;
    }
    .container-hdr .button-sec-hdr{
        display: flex;
        flex-direction: row;
        column-gap: 20px;
        row-gap: 20px;
    }
    .container-hdr.button-sec-hdr button{
        background-color: #E53F71;
        color: #E53F71;
        display: flex;
        padding: 12px 14px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }
    .container-hdr .button-sec-hdr .fst, .Snd {
        color: #FFF;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 142.857% */
        text-transform: capitalize;
        background-color: #E53F71;
        outline: none;
        border: none;
        display: flex;
        padding: 10px 20px;
        align-items: center;
        gap: 0;
        display: flex;
        padding: 12px 14px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }
    .container-hdr .button-sec-hdr .fst, .Snd svg{
        width: 24px;
    }
}

@media (min-width:1200px){
    .custom-navbar {
        background-color: transparent !important; 
    }
    .nv-height{
        height: 5vw;
        padding: 0px 10vw;
    }
    .nav-link{
        padding-left: 0;
        padding-right: 0;
    }
    .logo{
        height: 3.6458333333333335vw;
    }
    .mdl-set{
        column-gap: 20px;
    }
    .slick-slide{
        /* margin-top: -5vw !important; */
        height: 100vh;
    }
    .slick-slide img{
        display: block;
        width: 100%;
        height: 100vh;
        background: linear-gradient(87deg, rgba(0, 0, 0, 0.40) 2.28%, rgba(59, 2, 19, 0.03) 97.72%);
        object-fit: cover;
    }
    .container-hdr{ 
        display: block;
        position: absolute;
        top: 14.583333333333334vw;
        z-index: 9;
        width: 55vw !important;
        color: #fff;
        /* left: 50%; */
        padding-left: 10.8vw;
    }
    .container-hdr .sp-span{
        color: #FFF;
        font-family: Inter;
        font-size:  0.78125vw;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4322916666666667vw; /* 183.333% */
        padding-bottom:  1.0416666666666667vw;
        display: block;
    }
    .container-hdr .highlighted-word{
        color: #e53f71;
        font-family: Montserrat;
        font-size: 3.6458333333333335vw;
        font-style: normal;
        font-weight: 700;
        line-height: 3.6458333333333335vw;
        text-transform: uppercase;
    }
    .container-hdr h1{
        color: #FFF;
        text-align: left;
        font-family: Montserrat;
        font-size: 3.6458333333333335vw;
        font-style: normal;
        font-weight: 700;
        line-height: 3.6458333333333335vw; /* 100% */
        text-transform: uppercase;
        padding: 0;
        padding-bottom: 1.0416666666666667vw;
        margin: 0;
    }
    .container-hdr p{
        color: #FFF;
        font-family: Inter;
        font-size: 0.9114583333333334vw;
        font-style: normal;
        font-weight: 500;
        line-height: 1.4322916666666667vw; /* 157.143% */
        display: block;
    }
    .container-hdr .button-sec-hdr{
        display: flex;
        flex-direction: row;
        column-gap: 1.5625vw;
    }
    .container-hdr.button-sec-hdr button{
        background-color: #E53F71;
        color: #E53F71;
    }
    .container-hdr .button-sec-hdr .fst, .Snd {

        padding: 10px 22px;

        color: #FFF;
        font-family: Inter;
        font-size: 0.9114583333333334vw;
        font-style: normal;
        font-weight: 500;
        line-height: 1.3020833333333333vw; /* 142.857% */
        text-transform: capitalize;
        background-color: #E53F71;
        outline: none;
        border: none;
        display: flex;
        align-items: center;
    }
    .container-hdr .button-sec-hdr .fst, .Snd svg{
        width: auto;
    }
}




  
  
