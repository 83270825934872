.howItWorks-Wrapper{
    padding: 20px 24px;
    background: #FFF7FA;
}
.pprbc{
    background: #FCF7FF;
}
.howItWorks-Wrapper .main-topic{
    display: block;
    color: #000;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px; /* 141.667% */
    text-transform: uppercase;
    padding-bottom: 60px;
    text-align: center;
}


.howItWorks-Wrapper .comp-flex-all{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    
}
.howItWorks-Wrapper .comp-flex-all .Single-main-div{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    column-gap: 24px;
    margin-bottom: 50px;
}
.howItWorks-Wrapper .comp-flex-all .Single-main-div .number{
    height: 60px;
    width: 60px;
    background: #E54D7B;
    border-radius: 50%;
    position: relative;
    z-index: 0;
}

.howItWorks-Wrapper .comp-flex-all .Single-main-div .number::after{
    content: '';
    position: absolute;
    bottom: -140px;
    left: 50%;
    transform: translateX(-50%);
    height: 125px;
    width: 2px;
    background: #E53F71;
}
.howItWorks-Wrapper .comp-flex-all .Single-main-div .purpleNumber{
    height: 60px;
    width: 60px;
    background: #A83FE5;
    border-radius: 50%;
    position: relative;
    z-index: 0;
}

.howItWorks-Wrapper .comp-flex-all .Single-main-div .purpleNumber::after{
    content: '';
    position: absolute;
    bottom: -140px;
    left: 50%;
    transform: translateX(-50%);
    height: 125px;
    width: 2px;
    background: #A83FE5;
}

.howItWorks-Wrapper .comp-flex-all .Single-main-div:last-child .number::after{
 content: none;
}
.howItWorks-Wrapper .comp-flex-all .Single-main-div:last-child .purpleNumber::after{
    content: none;
   }
.howItWorks-Wrapper .comp-flex-all .Single-main-div .number span{
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FFF;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 100% */
}

.howItWorks-Wrapper .comp-flex-all .Single-main-div .inner-flex{
    display: flex;
    flex-direction: column;
    width: 71%;
}
.howItWorks-Wrapper .comp-flex-all .Single-main-div .inner-flex .main-txt{
    color: #282828;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    padding-bottom: 32px;
    line-height: 24px; /* 133.333% */
}

.howItWorks-Wrapper .comp-flex-all .Single-main-div .inner-flex .para{
    color: #A1A1A1;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 175% */
}

@media (min-width:768px){
    .howItWorks-Wrapper{
        padding: 20px 24px;
    }
    
    .howItWorks-Wrapper .main-topic{
        display: block;
        color: #000;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px; /* 141.667% */
        font-variant: small-caps;
        padding-bottom: 60px;
        text-align: center;
    }
    
    
    .howItWorks-Wrapper .comp-flex-all{
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        
    }
    .howItWorks-Wrapper .comp-flex-all .Single-main-div{
        display: flex;
        justify-content: flex-start;
        width: 100%;
        column-gap: 24px;
        margin-bottom: 50px;
    }
    .howItWorks-Wrapper .comp-flex-all .Single-main-div .number{
        height: 60px;
        width: 60px;
        background: #E54D7B;
        border-radius: 50%;
        position: relative;
        z-index: 0;
    }
    
    .howItWorks-Wrapper .comp-flex-all .Single-main-div .number::after{
        content: '';
        position: absolute;
        bottom: -72px;
        left: 50%;
        transform: translateX(-50%);
        height: 60px;
        width: 2px;
        background: #E53F71;
    }
    .howItWorks-Wrapper .comp-flex-all .Single-main-div:last-child .number::after{
     content: none;
    }
    .howItWorks-Wrapper .comp-flex-all .Single-main-div .number span{
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #FFF;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 100% */
    }
    
    .howItWorks-Wrapper .comp-flex-all .Single-main-div .inner-flex{
        display: flex;
        flex-direction: column;
        width: 71%;
    }
    .howItWorks-Wrapper .comp-flex-all .Single-main-div .inner-flex .main-txt{
        color: #282828;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        padding-bottom: 32px;
        line-height: 24px; /* 133.333% */
    }
    
    .howItWorks-Wrapper .comp-flex-all .Single-main-div .inner-flex .para{
        color: #A1A1A1;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px; /* 175% */
    }
}


@media (min-width:1200px){
    .howItWorks-Wrapper{
        padding: 5.20833vw 10.15625vw;
    }
    
    .howItWorks-Wrapper .main-topic{
        color: #000;
        font-size:  2.08335vw;
        font-style: normal;
        font-weight: 700;
        line-height: 2.2135416vw; /* 106.25% */
        font-variant: small-caps;
        padding-bottom: 4.6875vw;
        text-align: center;
    }
    
    
    .howItWorks-Wrapper .comp-flex-all{
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: flex-start;
        column-gap: 3.77604165vw;
    }
    .howItWorks-Wrapper .comp-flex-all .Single-main-div{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 3.25520833vw;
    }
    .howItWorks-Wrapper .comp-flex-all .Single-main-div .number{
        height: 60px;
        width: 60px;
        background: #E54D7B;
        border-radius: 50%;
        position: relative;
        z-index: 0;
        margin-bottom: 2.0833vw;
    }

    .howItWorks-Wrapper .comp-flex-all .Single-main-div .purpleNumber{
        height: 60px;
        width: 60px;
        background: #A83FE5;
        border-radius: 50%;
        position: relative;
        z-index: 0;
        margin-bottom: 2.0833vw;
    }
    
    .howItWorks-Wrapper .comp-flex-all .Single-main-div .number::after{
        content: '';
        position: absolute;
        bottom: 50%;
        left: 5.908vw;
        transform: translateY(-50%);
        height: 2px;
        width: 13.02083vw;
        background: #E53F71;
    }

    .howItWorks-Wrapper .comp-flex-all .Single-main-div .purpleNumber::after{
        content: '';
        position: absolute;
        bottom: 50%;
        left: 5.908vw;
        transform: translateY(-50%);
        height: 2px;
        width: 13.02083vw;
        background: #A83FE5;
    }
    .howItWorks-Wrapper .comp-flex-all .Single-main-div:last-child .number::after{
     content: none;
    }
    .howItWorks-Wrapper .comp-flex-all .Single-main-div .number span{
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #FFF;
        font-family: Montserrat;
        font-size: 1.302083vw;
        font-style: normal;
        font-weight: 700;
        line-height: 1.302083vw; /* 100% */
    }
    
    .howItWorks-Wrapper .comp-flex-all .Single-main-div .inner-flex{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .howItWorks-Wrapper .comp-flex-all .Single-main-div .inner-flex .main-txt{
        color: #282828;
        font-family: Inter;
        font-size: 1.171875vw;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5625vw; /* 133.333% */
        padding-bottom:  2.0835vw;
    }
    
    .howItWorks-Wrapper .comp-flex-all .Single-main-div .inner-flex .para{
        color: #A1A1A1;
        font-family: Inter;
        font-size: 0.78125vw;
        font-style: normal;
        font-weight: 500;
        line-height: 1.3671875vw; /* 175% */
    }
}