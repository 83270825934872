.banner-inner{
    position: relative;
    width: 100%;
    z-index: 0;
}

.banner-inner .bgSub{
    position: relative;
    width: 100%;
    z-index: 0;
    height: 85vh;
    object-fit: cover;
}

.banner-inner .absTxt{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 0px 52px;
    z-index: 1;
}

.banner-inner .absTxt .main-txt{
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
    text-transform: uppercase;
    padding-bottom: 10px;
    display: block;
}

.banner-inner .absTxt .pagination{
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
    text-transform: uppercase;
    display: block;
    
}

@media (min-width:1200px){
    .banner-inner{
        position: relative;
        width: 100%;
        z-index: 0;
    }
    
    .banner-inner .bgSub{
        position: relative;
        width: 100%;
        z-index: 0;
        height: 75vh;
        object-fit: cover;
    }
    
    .banner-inner .absTxt{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 0px 31.640625vw;
        z-index: 1;
    }
    
    .banner-inner .absTxt .main-txt{
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 2.6041666666666665vw;
        font-style: normal;
        font-weight: 700;
        line-height: 3.6458333333333335vw; /* 140% */
        text-transform: uppercase;
    }
    
    .banner-inner .absTxt .pagination{
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 0.9114583333333334vw;
        font-style: normal;
        font-weight: 500;
        line-height: 0.9114583333333334vw; /* 100% */
        text-transform: uppercase;
        
    }
}