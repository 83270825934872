.RftlRegister{
    padding: 40px 24px;
}
.RftlRegister .flx-wrapp{
    display: flex;
    flex-direction: column-reverse;
    row-gap: 60px;
}

.RftlRegister .flx-wrapp .left-side .smallTag{
    color: #B3B3B3;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 107.692% */
    text-transform: uppercase;
    padding-bottom: 16px;
    display: block;
}

.RftlRegister .flx-wrapp .left-side .BigTag{
    color: #050505;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 58.333% */
    text-transform: capitalize;
    padding-bottom: 32px;
    display: block;
}

.RftlRegister .flx-wrapp .left-side .runRegisterImg{
    width: 100%;
    border-radius: 12px;
    margin-bottom: 32px;
}
.RftlRegister .flx-wrapp .left-side .RegisterRunP{
    color: #727272;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
}

.RftlRegister .flx-wrapp .right{
    padding: 32px 24px;
    border-radius: 12px;
    background: #FAFAFA;

}
.RftlRegister .flx-wrapp .right .lableReg{
    display: block;
    padding: 12px 16px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 4px;
    background: #FCE6ED;
    color: #E53F71;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px; /* 100% */
    text-transform: capitalize;
    width: fit-content;
    margin-bottom: 24px;

}
.RftlRegister .flx-wrapp .right h2{
    color: #050505;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 160% */
    text-transform: capitalize;
    padding-bottom: 32px;
}
.RftlRegister form .no-flx, .single{
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.RftlRegister form .no-flx, .single label{
    color: #4E4E4E;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 100% */
    text-transform: capitalize;
    margin-bottom: 12px;
}
::placeholder {
    color: #D7D7D7;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px; /* 100% */
    text-transform: capitalize;
}

.RftlRegister form .no-flx input[type="text"], input[type="tel"], input[ type="date"], input[ type="email"], input[ type="number"]{
    display: flex;
    padding: 15px 24px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #E7E7E7;
    background: #FFF;
    width: 100%;
    color: #D7D7D7;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px; /* 100% */
    text-transform: capitalize;
}
.spcSelect{
    display: flex;
    padding: 13px 24px;
    justify-content: center;
    align-items: center;
    gap: 32px;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    background: #FFF;
    width: 100%;
    color: #D7D7D7;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px; /* 100% */
    text-transform: capitalize;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 92%;
    background-position-y: 50%;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    margin-right: 2rem;
    padding: 1rem;
    padding-right: 2rem;
}
.RftlRegister form .single select{
    display: flex;
    padding: 13px 24px;
    justify-content: center;
    align-items: center;
    gap: 32px;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    background: #FFF;
    width: 70%;
    color: #D7D7D7;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px; /* 100% */
    text-transform: capitalize;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 92%;
    background-position-y: 50%;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    margin-right: 2rem;
    padding: 1rem;
    padding-right: 2rem;
}
input {border:0;outline:0;}
input:focus {outline:none!important;}
select:focus {outline:none!important;}
select option{
    color: #050505;
}

form .smallDiv{
    color: #A1A1A1;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 140% */
    padding-bottom: 12px;
    display: block;
}

form .checkAgree{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    column-gap: 12px;

    color: #6C6C6C;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 155.556% */
    margin-bottom: 32px;
}

.RFTLInSub{
    display: flex;
    padding: 12px 14px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    background: #E53F71;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    border: none;
    width: 100%;
    text-transform: capitalize;
}

 .toggleButton{
    display: flex;
    width: 100%;
    padding: 16px 24px;
    align-items: center;
    gap: 0;
    border-radius: 6px;
    background: #F4E6FC;
    color: #A83FE5;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 87.5% */
    text-transform: capitalize;
    border: none;
    margin-bottom: 24px;
    margin-top: 24px;
    justify-content: space-between;
}
 .contentToToggle{
    margin-bottom: 24px;
}
 .contentToToggle .tg-row{
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-bottom: 12px;
    padding-top: 12px;
    border-bottom: 1px solid #D7D7D7;
}
 .contentToToggle .tg-row .top-rw{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 12px;
    align-items: center;
}
 .contentToToggle .tg-row .top-rw:last-child {
    padding-bottom: 0;
}
 .contentToToggle .tg-row .top-rw .lft-tg{
    color: #4E4E4E;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 100% */
}
 .contentToToggle .tg-row .top-rw .rgt-tg{
    color: #7D7D7D;
    text-align: right;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 107.692% */
}
 .contentToToggle .tg-row input[type="number"]{
    display: flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 42px;
    border-radius: 4px;
    border: 1px solid #E7E7E7;
    background: #FFF;
    color: #7D7D7D;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px; /* 100% */
    text-transform: capitalize;
    width: 120px;
}

.RftlRegister .flx-wrapp .right .lableGrpReg{
    border-radius: 4px;
    background: #F4E6FC;
    color: #A83FE5;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 100% */
    text-transform: capitalize;
}
.GrpSubmit{
    border-radius: 4px;
    background: #A83FE5;
}
@media (min-width:1200px){
    .RftlRegister{
        padding: 5.20833vw 10.15625vw;
    }
    .RftlRegister .flx-wrapp{
        display: flex;
        flex-direction: row;
        column-gap: 120px;
    }
    .RftlRegister .flx-wrapp .left-side{
        width: 40%;
    }

    .RftlRegister .flx-wrapp .right{
        width: 50%;
    }
    .RftlRegister .flx-wrapp .left-side .smallTag{
        color: #B3B3B3;
        font-family: Inter;
        font-size: 0.8463vw;
        font-style: normal;
        font-weight: 500;
        line-height: 0.911vw; /* 107.692% */
        text-transform: uppercase;
        padding-bottom: 1.0416vw;
        display: block;
    }

    .RftlRegister .flx-wrapp .left-side .BigTag{
        color: #050505;
        font-family: Inter;
        font-size: 1.5625vw;
        font-style: normal;
        font-weight: 600;
        line-height: 0.911vw; /* 58.333% */
        text-transform: capitalize;
        padding-bottom: 2.0833vw;
        display: block;
    }

    .RftlRegister .flx-wrapp .left-side .runRegisterImg{
        width: 100%;
        border-radius: 0.78125vw;
        margin-bottom: 2.0833vw;
    }
    .RftlRegister .flx-wrapp .left-side .RegisterRunP{
        color: #727272;
        font-family: Inter;
        font-size: 0.78125vw;
        font-style: normal;
        font-weight: 400;
        line-height: 1.30208vw; /* 166.667% */
    }


    .RftlRegister .flx-wrapp .right{
        padding: 32px 40px;
        border-radius: 12px;
        background: #FAFAFA;

    }
    .RftlRegister .flx-wrapp .right .lableReg{
        display: block;
        padding: 12px 16px;
        align-items: flex-start;
        gap: 10px;
        border-radius: 4px;
        background: #FCE6ED;
        color: #E53F71;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px; /* 100% */
        text-transform: capitalize;
        width: fit-content;
        margin-bottom: 24px;

    }
    .RftlRegister .flx-wrapp .right h2{
        color: #050505;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px; /* 58.333% */
        text-transform: capitalize;
        padding-bottom: 32px;
    }
    .RftlRegister form .no-flx, .single{
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
    }

    .RftlRegister form .no-flx, .single label{
        color: #4E4E4E;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px; /* 100% */
        text-transform: capitalize;
        margin-bottom: 12px;
    }
    .RftlRegister form .spcFlx{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin: 0;
    }
    .RftlRegister form .spcFlx .single{
        width: 70%;

    }
    .RftlRegister form .spcFlx .single input{
        width: 80%;
    }
    .RftlRegister form .spcFlx label{
        width: fit-content;
    }
    .RftlRegister form .spcFlx select{
        margin-right: 0 !important;
    }
    ::placeholder {
        color: #D7D7D7;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px; /* 100% */
        text-transform: capitalize;
    }

    .RftlRegister form .no-flx input[type="text"], input[type="tel"], input[ type="date"], input[ type="email"], input[ type="number"]{
        display: flex;
        padding: 15px 24px;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #E7E7E7;
        background: #FFF;
        width: 100%;
        color: #D7D7D7;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px; /* 100% */
        text-transform: capitalize;
    }
    .RftlRegister form .single select{
        display: flex;
        padding: 13px 24px;
        justify-content: center;
        align-items: center;
        gap: 32px;
        border-radius: 4px;
        border: 1px solid #F0F0F0;
        background: #FFF;
        width: 70%;
        color: #D7D7D7;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px; /* 100% */
        text-transform: capitalize;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 92%;
        background-position-y: 50%;
        border: 1px solid #dfdfdf;
        border-radius: 2px;
        margin-right: 2rem;
        padding: 1rem;
        padding-right: 2rem;
    }
    input {border:0;outline:0;}
    input:focus {outline:none!important;}
    select:focus {outline:none!important;}
    select option{
        color: #050505;
    }

    form .smallDiv{
        color: #A1A1A1;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 140% */
        padding-bottom: 12px;
        display: block;
    }

    form .checkAgree{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        font-family: Inter;
        font-size: 9px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        line-height: 14px;
        margin-bottom: 32px;
        column-gap: 10px;
    }

    .RFTLInSub{
        display: flex;
        padding: 12px 14px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 4px;
        background: #E53F71;
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 142.857% */
        border: none;
        width: 100%;
        text-transform: capitalize;
        margin-top: -5%;
    }
    .GrpSubmit{
        border-radius: 4px;
        background: #A83FE5;
    }
    .RftlRegister .flx-wrapp .right .lableGrpReg{
        border-radius: 4px;
        background: #F4E6FC;
        color: #A83FE5;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px; /* 100% */
        text-transform: capitalize;
    }
}

@media (max-width:1199px){
    .mobile-booking-container {
        display: flex;
        flex-direction: column-reverse;
    }
}
