.galleryWrapper {
    padding: 60px 24px;
}

.GalTop {
    color: #000;
    text-align: center;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px; /* 141.667% */
    font-variant: small-caps;
    display: block;
}

.miniTop {
    color: #767676;
    display: block;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
    padding-top: 16px;
    padding-bottom: 32px;
}

.tabContent .tabs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 32px;

}

.tabContent .tabs button {
    display: flex;
    width: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
    flex-shrink: 0;
    color: #5D5D5D;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    background: none;
    border: none;
}

.tabs button.active {
    border-bottom: 2px solid #e53f71;
}

.all-img-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin-bottom: 5%;
}

.all-img-wrapper img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
}

.all-img-wrapper img {
    display: none;
}

.all-img-wrapper img:first-child {
    display: block;
}

.additional {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.additional img {
    display: block;
    width: 100%;
}

.ShowBtnSp {
    margin-top: 32px;
    display: flex;
    padding: 10px 24px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: #E53F71;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
    border: none;
    width: fit-content;
    margin: 0 auto;
}

@media (min-width: 768px) {
    .galleryWrapper {
        padding: 60px 24px;
    }

    .GalTop {
        color: #000;
        text-align: center;
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px; /* 141.667% */
        font-variant: small-caps;
        display: block;
    }

    .miniTop {
        color: #767676;
        display: block;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        text-transform: capitalize;
        padding-top: 16px;
        padding-bottom: 32px;
    }

    .tabContent .tabs {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 32px;

    }

    .tabContent .tabs button {
        display: flex;
        width: 100px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 3px;
        flex-shrink: 0;
        color: #5D5D5D;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        background: none;
        border: none;
    }

    .tabs button.active {
        border-bottom: 2px solid #e53f71;
    }

    .all-img-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 24px;
        align-items: flex-start;
        justify-content: space-between;

    }


    .all-img-wrapper img {
        width: 24%;
    }

    .all-img-wrapper img {
        display: block;
    }

    .all-img-wrapper img:first-child {
        display: block;
    }

    .additional {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        column-gap: 16px;
    }

    .additional img {
        display: block;
        width: 24%;
    }
}

@media (min-width: 1200px) {
    .galleryWrapper {
        padding: 80px 156px;
    }

    .GalTop {
        color: #000;
        font-family: Montserrat;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px; /* 106.25% */
        text-transform: uppercase;
        display: block;
    }

    .miniTop {
        color: #767676;
        display: block;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        text-transform: capitalize;
        padding-top: 16px;
        padding-bottom: 40px;
    }

    .tabContent .tabs {
        display: flex;
        justify-content: center;
        width: 100%;
        column-gap: 140px;
        margin-bottom: 40px;

    }

    .tabContent .tabs button {
        display: flex;
        width: fit-content;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 3px;
        flex-shrink: 0;
        color: #5D5D5D;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        background: none;
        border: none;
    }

    .tabs button.active {
        border-bottom: 2px solid #e53f71;
    }

    .all-img-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        column-gap: 24px;
    }


    .all-img-wrapper img {
        width: 32%;
    }

    .all-img-wrapper img {
        display: block;
    }

    .all-img-wrapper img:first-child {
        display: block;
    }

    .additional {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 24px;
        align-items: flex-start;
        justify-content: flex-start;
        column-gap: 24px;
    }

    .additional img {
        display: block;
        width: 32%;
    }

    .ShowBtnSp {
        margin-top: 40px;
        display: flex;
        padding: 10px 24px;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        background: #E53F71;
        color: #FFF;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        text-transform: capitalize;
        border: none;
        width: fit-content;
        margin: 0 auto;
    }
}
