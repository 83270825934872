.FooterWrapper{
    padding: 56px 24px 40px;
    background: #050505;
  
}
.main-3-col-wrapper{
    display: flex;
    flex-direction: column;
  border-bottom: 1px solid #3A3A3A;

}
.main-3-col-wrapper .left-main{
    display: block;
}
.main-3-col-wrapper .left-main img{
    width: 100%;
}

.main-3-col-wrapper .left-main img{
    width: 80px;
}
.main-3-col-wrapper .left-main .lft-main-txt{
    margin-top: 24px;
    display: block;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */

}

.rgt-main{
    margin-top: 32px;
    display: flex;
    flex-direction: column;
}
.rgt-lft .small-heading{
    display: block;
    color: #FFF;
    text-align: left;
    leading-trim: both;
    text-edge: cap;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px; /* 212.5% */
    text-transform: capitalize;
    padding-bottom: 31px;    
}
.rgt-lft ul{
    list-style: none;
    padding: 0;
    margin: 0;
}

.rgt-lft ul li a{
    color: #D7D7D7;
    text-align: left;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px; /* 283.333% */
    text-transform: uppercase;
    display: block;
    text-decoration: none;
}

.rgt-rgt{
    margin-top: 32px;
}

.rgt-rgt .small-heading{
    color: #FFF;
    text-align: left;
    leading-trim: both;
    text-edge: cap;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px; /* 212.5% */
    text-transform: capitalize;
    padding-bottom: 31px;
    display: block;
}

.rgt-rgt .flx-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    
    margin-bottom: 34px;
    column-gap: 12px;
}

.double{
    display: flex;
    flex-direction: column;
}
.boldtxt{
    color: #FEFEFE;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 116.667% */
    text-transform: uppercase;
    display: block;   
    padding-bottom: 4px;
}

.thintxt{
    color: #FEFEFE;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 116.667% */
}

.thintxt a{
    color: #FEFEFE;
}

.copyright{
    padding:  40px 0 56px;
    display: flex;
    align-items: baseline;
}

.copyright svg{
    width: 20%;
}

.copyright span{
    display: block;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */    
}

@media (min-width:1200px){ 
    .FooterWrapper{
        padding: 3.6458333333333335vw 10.15625vw;
        background: #050505;
      
    }
    .main-3-col-wrapper{
        display: flex;
        flex-direction: row;
        column-gap: 12.109375vw;
        border-bottom: 1px solid #3A3A3A;
    
    }
    .main-3-col-wrapper .left-main{
        display: block;
        width: 30%;
    }
    .main-3-col-wrapper .left-main img{
        width: 100%;
    }
    
    .main-3-col-wrapper .left-main img{
        width: 5.208333333333333vw;
    }
    .main-3-col-wrapper .left-main .lft-main-txt{
        margin-top: 1.5625vw;
        display: block;
        color: #FFF;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px; /* 142.857% */
    
    }
    
    .rgt-main{
        margin-top: 0;
        display: flex;
        flex-direction: row;
        column-gap: 12.109375vw;
        width: 50%;
    }
    .rgt-lft, .rgt-rgt{
        width: 50%;
    }
    .rgt-lft .small-heading{
        display: block;
        color: #FFF;
        text-align: left;
        leading-trim: both;
        text-edge: cap;
        font-family: Montserrat;
        font-size: 1.0416666666666667vw;
        font-style: normal;
        font-weight: 700;
        line-height: 2.2135416666666665vw; /* 212.5% */
        text-transform: capitalize;
        padding-bottom: 2.0182291666666665vw;    
    }
    .rgt-lft ul{
        list-style: none;
        padding: 0;
        margin: 0;
    }
    
    .rgt-lft ul li a{
        color: #D7D7D7;
        text-align: left;
        font-family: Inter;
        font-size: 0.78125vw;
        font-style: normal;
        font-weight: 400;
        line-height: 2.2135416666666665vw; /* 283.333% */
        text-transform: uppercase;
        display: block;
        text-decoration: none;
    }
    
    .rgt-rgt{
        margin-top: 0;
        margin-left: -10%;
    }
    .rgt-rgt .small-heading{
        color: #FFF;
        text-align: left;
        leading-trim: both;
        text-edge: cap;
        font-family: Montserrat;
        font-size: 1.0416666666666667vw;
        font-style: normal;
        font-weight: 700;
        line-height: 2.2135416666666665vw; /* 212.5% */
        text-transform: capitalize;
        padding-bottom: 2.0182291666666665vw;
        display: block;
    }
    
    .rgt-rgt .flx-div{
        display: flex;
        flex-direction: row;
        align-items: center;
        
        margin-bottom: 2.2135416666666665vw;
        column-gap: 0.8125vw;
    }
    
    .double{
        display: flex;
        flex-direction: column;
    }
    .boldtxt{
        color: #FEFEFE;
        font-family: Inter;
        font-size: 0.78125vw;
        font-style: normal;
        font-weight: 700;
        line-height: 14px; /* 116.667% */
        text-transform: uppercase;
        display: block;   
        padding-bottom: 4px;
    }
    
    .thintxt{
        color: #FEFEFE;
        font-family: Inter;
        font-size: 0.78125vw;
        font-style: normal;
        font-weight: 400;
        line-height: 0.9114583333333334vw; /* 116.667% */
    }
    
    .copyright{
        padding:  30px 0 0px;
        display: flex;
        align-items: baseline;
        row-gap: 0.5208333333333334vw;
    }
    
    .copyright svg{
        width: 2%;
    }
    
    .copyright span{
        display: block;
        color: #FFF;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.3020833333333333vw; /* 142.857% */    
    }
}